import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import "./hero.scss";
import InternalButton from "../../components/internalButton/internalButton";
import Button from "../../components/button/button";
import { useLocation } from "@reach/router";
import { createGlobalStyle } from 'styled-components';
import RightArrow from "../../images/icons/right-arrow-icon.svg"
// import Img from "gatsby-image";

// Things commented out due to design changes, keeping them in just in case they change their minds

const Hero = ({
  hasLogo,
  brandLogo,
  brandAlt,
  hasIcon,
  heroIcon,
  iconAlt,
  headline,
  copy,
  hasButton,
  videoBtn,
  isExternalButton,
  btnLink,
  btnTitle,
  btnText,
  btnClass,
  videoBtnClass,
  label,
  videoOnClick,
  iconClass,
  subheadline,
  minHeight,
  buttonIcon,
  iconText,
  iconUrl,
  videoBtnID,
  linkId,
  flip=false
}) => {
  const [isHomePage, setIsHomePage] = useState(false);
  const [hcpPage, setHcpPage] = useState(false);
  const [backgroundMobile, setBackgroundMobile] = useState("");
  const [backgroundDesktop, setBackgroundDesktop] = useState("");
  const [imageHeight, setImageHeight] = useState(250);

  let jevtanaPI = null;
  let sarclisaPI = null;
  let elitekPI = null;
  let brand = "";
  const location = useLocation();
  const desktopImgElement = useRef(null);
  const mobileImgElement = useRef(null);

  const submitURL = () => {
    let windowLocation = location.href;
    let brand = "elitek";

    if (windowLocation !== undefined) {
      if (windowLocation.includes("elitek")) {
        brand = "elitek";
        elitekPI = true;
      } else if (windowLocation.includes("sarclisa")) {
        brand = "sarclisa";
        sarclisaPI = true;
        return `https://products.sanofi.us/Sarclisa/sarclisa.pdf`;
      } else if (windowLocation.includes("jevtana")) {
        brand = "jevtana";
        jevtanaPI = true;
      }
    }
    return `https://products.sanofi.us/${brand}/${brand}.html`;
  };


  useEffect( () => {
    if (location.pathname === "/hcp/" || location.pathname === "/") {
      setIsHomePage(true);
    }
    if (location.pathname.includes("hcp")) {
      setHcpPage(true);
    }

    let path = location.pathname.toString();
    const parts = path.split('/').filter(val => val.trim() !== '');
    const count = parts.length;
    let name = path;
    if(count <= 2 && path.includes('hcp')) {
      path = count === 1 ? `${parts[0]}` : path;
      name = path + '/home';
    } else if(count <= 1 && !path.includes('hcp')) {
      path = count === 0 ? '' : path;
      name = `/pat${path}/home`;
    } else if(count > 1 && !path.includes('hcp')) {
      name = `/pat${path}`;
    }
    if (name.charAt(name.length-1) === '/') {
      name = name.substring(0, name.length-1);
    }
    const imageDesktop = '../../../images/hero/' + name + '.webp';
    const imageMobile = '../../../images/hero/' + name + '-mobile.webp';

    setBackgroundMobile(imageMobile);
    setBackgroundDesktop(imageDesktop);

    function handleResize() {
      if (window.innerWidth > 768) {
        setImageHeight(desktopImgElement.current.naturalHeight + desktopImgElement.current.offsetParent.offsetTop);
      } else {
        setImageHeight(mobileImgElement.current.naturalHeight + mobileImgElement.current.offsetParent.offsetTop);
      }
    }

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [true]);


  const GlobalStyles = createGlobalStyle`
      .hero {
         min-height: ${imageHeight}px;;
      }
      @media (min-width: 992px) {
        .hero {
           min-height: ${imageHeight}px;
           max-height: ${imageHeight}px;
        }
      }
`;

  return (
    <>
      <GlobalStyles />
      {isHomePage ? (
        <div className="pi-mobile-wrapper">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/elitek/elitek.html"
            target="_blank"
            id="patient-pi-elitek"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING, for ELITEK<sup>&reg;</sup> (rasburicase).
          <br />
          Please see full{" "}
          <a
            href="https://products.sanofi.us/jevtana/jevtana.html"
            target="_blank"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING,{" "}
          {!hcpPage && (
            <span>
              and{" "}
              <a
                target="_blank"
                href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21"
              >
                Patient Information
              </a>
            </span>
          )}{" "}
          for JEVTANA<sup>&reg;</sup> (cabazitaxel) Injection.
        </div>
      ) : (
        <div className="pi-mobile-wrapper">
          Please see full{" "}
          <a href={submitURL()} target="_blank">
            Prescribing Information
          </a>
          <span>
            {elitekPI && ", including Boxed WARNING"}
            {sarclisaPI && !hcpPage &&  (
              <span>
                , including&nbsp;
                <a
                  target="_blank"
                  href="https://products.sanofi.us/Sarclisa/sarclisa_patient_information.pdf"
                >
                  Patient Information
                </a>
              </span>
            )}
          </span>
          {jevtanaPI && (
            <span>
              , including Boxed WARNING
              {!hcpPage && (
                <span>
                  , and{" "}
                  <a
                    target="_blank"
                    href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21"
                  >
                    Patient Information
                  </a>
                </span>
              )}
            </span>
          )}
          .
        </div>
      )}

      {/* seperator */}
      {/* seperator */}
      {/* seperator */}

      {/* setting the min height */}
      <section className={`hero ${minHeight}`}>
        {/* Patient and HCP CareAssist homepages do not have a logo by design */}
        {hasLogo && (
          <figure className="hero-logo">
            <img src={brandLogo} alt={brandAlt} />
          </figure>
        )}
        <figure className="hero-wave"></figure>
        <figure className="hero-image">
          <img src={backgroundDesktop} alt="" className={classNames("show-desktop", {flip: flip})} ref={desktopImgElement} onLoad={() => {
            if (window.innerWidth > 768) {
              setImageHeight(desktopImgElement.current.naturalHeight + desktopImgElement.current.offsetParent.offsetTop);
            }
          } }  />
          <img src={backgroundMobile} alt="" className={classNames("show-mobile", {flip: flip})} ref={mobileImgElement} onLoad={() => {
            if (window.innerWidth <= 768) {
              setImageHeight(mobileImgElement.current.naturalHeight + mobileImgElement.current.offsetParent.offsetTop);
            }
          } }  />
        </figure>
        <div className="hero-content">
          {hasIcon && <img className="mobile-m-b-20" src={heroIcon} alt={iconAlt} />}
          <div className="hero-content-text">
            <h1>{headline}</h1>
            {/* A couple pages have an list in the header, use this prop */}
            {copy && <p>{copy}</p>}
            {/* <p dangerouslySetInnerHTML={{ __html: copy }} /> */}
            {subheadline ? <p className="subheadline"><a id={linkId} href={btnLink} className={`no-underline lh120 ${btnClass} `}>{subheadline}&nbsp;&nbsp;<i className={"icon-right-arrow " + btnClass}><img src={RightArrow} /></i></a></p>: ''}
            {/* A couple pages have an external link, use this prop */}
            <div className="mobile-text-center">
              {isExternalButton && (
                <>
                  <Button
                    url={btnLink}
                    title={btnTitle}
                    text={btnText}
                    className={btnClass}
                    iconClass={iconClass}
                    id={linkId}
                  />
                </>
              )}
              {iconText && <h3 className="text-with-arrow home m-0"><a id={linkId} href={iconUrl} target="_blank" className="no-underline lh120 home">{iconText}</a></h3>}

              {/* Most pages have an internal button, use this prop */}
              {hasButton && (
                <InternalButton
                  url={btnLink}
                  title={btnTitle}
                  text={btnText}
                  className={btnClass}
                  buttonIcon={buttonIcon}
                  linkId={linkId}
                />
              )}
              {videoBtn && (
                <button
                  className={"btn " + videoBtnClass}
                  onClick={videoOnClick}
                  id={videoBtnID}
                ><i className="video-icon"></i>
                  <span className="fs-19">{label}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;

import React from "react";
import "./iconWithText.scss"

function IconWithText({icon, alt, text, additionalClass}) {
  return (
      <figure className={`icon-with-text ${additionalClass}`}>
        <img src={icon} alt={alt} />
          <figcaption>
              <h4>{text}</h4>
          </figcaption>        
      </figure>
  );
}


export default IconWithText
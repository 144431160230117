import React from "react"
import "./circleCta.scss"
import InternalButton from "../internalButton/internalButton"
import { Link } from "gatsby"

import RightArrow from "../../images/icons/right-arrow-icon.svg"

const CircleCta = (props) => {

  return (
    <>
      <figure className={"circle-cta " + props.class}>
      <Link id={props.linkId} to={props.btnLink}><img src={props.img} alt={props.imgAlt} /></Link>
        
        <figcaption className="text-container">
          {props.piInfo}
        </figcaption>
        {/* <InternalButton
          url={props.btnLink}
          title={props.btnTitle}
          text={props.btnText}
          className={props.btnClass}
        /> */}
        
        <Link id={props.linkId} to={props.btnLink}><i className={"icon-right-arrow " + props.btnClass}><img src={RightArrow} /></i></Link>
      </figure>
    </>
  )
}

export default CircleCta
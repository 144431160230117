import React from "react"
import "./havequestions.scss"
// import questionsIcon from "../../images/Unsorted-ASSETS/questions-icon.svg"

const HaveQuestions = (props) => {

    return (
      <>
        <div className="have-questions"> 
            <img src={props.icon} alt="Have questions icon"/>
            <div className="text-container">
                <h4>
                    Questions? Our Care Managers are here to help
                    <p>Contact us: <br className="mb"/> <b>Monday through Friday, <br className="mobilebr"/>9 <small>AM</small> &ndash; 8 <small>PM</small> ET</b></p>
                    <p>Phone: <br className="mb"/>  <b><a className="phone-number" href="tel:1-833-930-2273">1-833-WE+CARE</a> (<a className="phone-number" href="tel:1-833-930-2273">1-833-930-2273</a>)</b>&nbsp;&nbsp;&nbsp;<br className="mb"/><span className="mb span">Fax: <br className="mb"/>  <b>1&zwnj;-&zwnj;85&zwnj;5&zwnj;-&zwnj;4&zwnj;11&zwnj;-&zwnj;9&zwnj;6&zwnj;89</b></span> </p>
                    <p>Mail: <br className="mb"/> <b> CareASSIST by Sanofi, 450 Water St., 3rd fl, <br className="mobilebr"/>Cambridge, MA 02141</b></p>
                </h4>
            </div>
        </div>
      </>
    )
  }
  
  export default HaveQuestions